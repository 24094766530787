import React, {useContext} from 'react';
import {Route, Routes} from 'react-router';
import {BrowserRouter} from 'react-router-dom';

import {UserInfoContext} from '../context/UserInfoContext';
import indexRoutes from '.';
import Redirect from './Redirect';

const MainRouter = () => {
  const {userInfo} = useContext<any>(UserInfoContext);

  return (
    <BrowserRouter>
      <Routes>
        {indexRoutes.map((prop: any) => {
          if (prop.private) {
            return (
              <Route
                key={prop.path}
                path={prop.path}
                element={
                  userInfo.connected ? prop.component : <Redirect to="/login" />
                }
              />
            );
          }
          return (
            <Route path={prop.path} element={prop.component} key={prop.path} />
          );
        })}
      </Routes>
    </BrowserRouter>
  );
};

export default MainRouter;
