/* eslint-disable */
import React from 'react';
import withStyles from '@mui/styles/withStyles';
import Grid from '@mui/material/Grid';

const style = {
  grid: {
    padding: '0 15px !important',
    margin: '0 auto',
  },
};

function GridItem({...props}) {
  const {classes, children, ...rest} = props;
  return (
    <Grid item {...rest} className={classes.grid}>
      {children}
    </Grid>
  );
}

export default withStyles(style)(GridItem);
