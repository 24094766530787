import React from 'react';
import {adaptV4Theme} from '@mui/material/styles';
import ReactDOM from 'react-dom';
import {ApolloProvider} from '@apollo/client';
// import { withClientState } from 'apollo-link-state';
import {ToastContainer} from 'react-toastify';

import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
  ThemeOptions,
} from '@mui/material';

import './assets/css/material-dashboard-react.css';

import 'react-toastify/dist/ReactToastify.min.css';
import {UserInfoProvider} from './context/UserInfoContext';
import {MessageContainersProvider} from './context/MessageContainersContext';
import MainRouter from './routes/MainRouter';
import client from './config/apolloClient';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://d2cb41b8b64d09b8532bf2c48eaf37c2@o4505018978205696.ingest.sentry.io/4506662360449024',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#07b5f3',
      // contrastText: '#ffffff',
    },
    secondary: {
      main: '#f44b03',
    },
  },
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <ToastContainer />
        <UserInfoProvider>
          <MessageContainersProvider>
            <MainRouter />
          </MessageContainersProvider>
        </UserInfoProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </ApolloProvider>,
  document.getElementById('root'),
);
