/* eslint-disable */
import {container} from '../../material-dashboard-react';

import backgroundImage from '../../../img/login-background.jpg';

const signupPageStyle = {
  container: {
    ...container,
    zIndex: '2',
    position: 'relative',
    paddingTop: '20vh',
    color: '#FFFFFF',
  },
  cardHidden: {
    opacity: '0',
    transform: 'translate3d(0, -60px, 0)',
  },
  pageHeader: {
    minHeight: '100vh',
    maxHeight: '1200px',
    height: 'auto',
    display: 'inherit',
    position: 'relative',
    margin: '0',
    padding: '0',
    border: '0',
    alignItems: 'center',
    '&:before': {
      background: 'rgba(0, 0, 0, 0.5)',
    },
    '&:before,&:after': {
      position: 'absolute',
      zIndex: '1',
      width: '100%',
      height: '100%',
      display: 'block',
      left: '0',
      top: '0',
      content: "''",
    },
    '& footer li a,& footer li a:hover,& footer li a:active': {
      color: '#FFFFFF',
    },
  },
  form: {
    margin: '0',
  },
  cardHeader: {
    width: 'auto',
    textAlign: 'center',
    marginLeft: '20px',
    marginRight: '20px',
    marginTop: '-40px',
    padding: '20px 0',
    marginBottom: '15px',
  },
  socialIcons: {
    maxWidth: '24px',
    marginTop: '0',
    width: '100%',
    transform: 'none',
    left: '0',
    top: '0',
    height: '100%',
    lineHeight: '41px',
    fontSize: '20px',
  },
  divider: {
    marginTop: '30px',
    marginBottom: '0px',
    textAlign: 'center',
  },
  cardFooter: {
    paddingTop: '0rem',
    border: '0',
    borderRadius: '6px',
    justifyContent: 'center !important',
  },
  socialLine: {
    marginTop: '1rem',
    textAlign: 'center',
    padding: '0',
  },
  inputIconsColor: {
    color: '#495057',
  },
  mainLogo: {
    marginTop: '30px',
    marginBottom: '30px',
    '& > div': {
      margin: 'auto',
    },
  },
  logoImg: {
    width: '100%',
    maxWidth: '400px',
  },
  // @ts-expect-error ts-migrate(1117) FIXME: An object literal cannot have multiple properties ... Remove this comment to see the full error message
  container: {
    minHeight: '100vh',
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    overflowX: 'hidden',
  },
  title: {
    fontSize: '2em',
    margin: 0,
  },
  passwordInputProgress: {
    height: '10px',
    background: '#eee',
    marginTop: '10px',
  },
  passwordInputProgressEmpty: {
    background: 'transparent',
  },
  passwordInputProgress1: {
    background: 'red',
  },
  passwordInputProgress2: {
    background: 'orange',
  },
  passwordInputProgress3: {
    background: '#FFE066',
  },
  passwordInputProgress4: {
    background: '#247BA0',
  },
  passwordInputProgress5: {
    background: 'green',
  },
};

export default signupPageStyle;
