/* eslint-disable */
import React from 'react';
import withStyles from '@mui/styles/withStyles';
import Grid from '@mui/material/Grid';

const style = {
  grid: {
    margin: '0 -15px !important',
    width: 'unset',
    flex: 1,
  },
};

function GridContainer(props: any) {
  const { classes, children, ...rest } = props;
  return (
    <Grid
      container
      {...rest}
      className={`${classes.grid} ${props.className ?? ''}`}>
      {children}
    </Grid>
  );
}

export default withStyles(style)(GridContainer);
