import {
  ApolloClient,
  ApolloLink,
  from,
  InMemoryCache,
  Observable,
  split,
} from '@apollo/client';
import {onError} from '@apollo/client/link/error';
import {WebSocketLink} from '@apollo/client/link/ws';
import {getMainDefinition} from '@apollo/client/utilities';
import {createUploadLink} from 'apollo-upload-client';
import {createLazyLoadableLaikaLink} from '@zendesk/laika';
import {APIUrl, WSUrl} from './API';
import {logout} from '../utils/auth';

const newLink = () => {
  const httpLink = createUploadLink({
    uri: APIUrl,
    fetchOptions: {
      credentials: 'include',
    },
  });

  // const wsLink = SocketLink.createSocketLink(serverAddr, https);
  const wsLink = new WebSocketLink({
    uri: WSUrl,
    options: {
      reconnect: true,
      lazy: true,
    },
  });

  const link = split(
    ({query}) => {
      // @ts-expect-error ts-migrate(2339)
      // FIXME: Property 'operation' does not exist on type 'Opera...
      const {kind, operation} = getMainDefinition(query);
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink,
    // @ts-ignore
    httpLink,
  );

  // ExpiredSessionError link
  const logoutLink = new ApolloLink((operation, forward) =>
    forward(operation).map(data => {
      if (data.data) {
        const keys = Object.keys(data.data);
        for (let i = 0; i < keys.length; i += 1) {
          if (data.data[keys[i]]?.__typename === 'ExpiredSessionError') {
            logout();
            window.location.assign('/?expiredSession');
          }
        }
      }
      return data;
    }),
  );

  const requestLink = new ApolloLink(
    (operation, forward) =>
      new Observable(observer => {
        let handle: any;
        Promise.resolve(operation)
          .then(() => {
            handle = forward(operation).subscribe({
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            });
          })
          .catch(observer.error.bind(observer));

        return () => {
          if (handle) handle.unsubscribe();
        };
      }),
  );

  return from([
    onError(err => {
      const {graphQLErrors, networkError} = err;
      if (graphQLErrors) {
        graphQLErrors.forEach(({message, locations, path}) => {
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          );
        });
      }
      if (networkError) console.log(`[Network error]: ${networkError}`);
      console.log(err);
    }),
    logoutLink,
    requestLink,
    ...(['development', 'test'].includes(import.meta.env.MODE)
      ? [
          createLazyLoadableLaikaLink({
            clientName: 'test-client',
            startLoggingImmediately: false,
          }),
        ]
      : []),
    link,
  ]);
};

const cache = new InMemoryCache();

const client = new ApolloClient({
  link: newLink(),
  cache,
});

// @ts-ignore
client.onResetStore(() => {
  client.setLink(newLink());
});

export default client;
