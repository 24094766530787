/* eslint-disable */
import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
import withStyles from '@mui/styles/withStyles';
// @mui/icons-material

// core components
import cardBodyStyle from '../../assets/jss/material-dashboard-react/components/cardBodyStyle';

type Props = {
  classes: any;
  className?: string;
  plain?: boolean;
  profile?: boolean;
  style?: any;
};

function CardBody({...props}: Props) {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'children' does not exist on type '{ clas... Remove this comment to see the full error message
  const {classes, className, children, plain, profile, ...rest} = props;
  const cardBodyClasses = classNames({
    [classes.cardBody]: true,
    [classes.cardBodyPlain]: plain,
    [classes.cardBodyProfile]: profile,
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [className]: className !== undefined,
  });
  return (
    <div className={cardBodyClasses} {...rest}>
      {children}
    </div>
  );
}

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ cardBody: { padding: string; f... Remove this comment to see the full error message
export default withStyles(cardBodyStyle)(CardBody);
