import React, {useEffect, useReducer} from 'react';

const localState = JSON.parse(localStorage.getItem('userInfo') || '{}');
const initialState = {
  connected: false,
  ...localState,
};

const reducer = (userInfo: any, {data: newUserInfo, action}: any) => {
  switch (action) {
    case 'UPDATE':
      return {
        ...userInfo,
        ...newUserInfo,
      };
    case 'RESET':
      return {
        connected: false,
      };
    default:
      return userInfo;
  }
};

// @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
const UserInfoContext = React.createContext();

const UserInfoProvider = ({
  // eslint-disable-line
  children,
}: any) => {
  const [userInfo, setUserInfo] = useReducer(reducer, initialState);

  const setConnected = (connected: boolean) => {
    setUserInfo({data: {connected}, action: 'UPDATE'});
  };

  const logout = () => {
    setUserInfo({action: 'RESET'});
  };

  useEffect(() => {
    localStorage.setItem('userInfo', JSON.stringify(userInfo));
  }, [userInfo]);

  const value = {
    // eslint-disable-line
    userInfo,
    setUserInfo: (data: any) => {
      setUserInfo({data, action: 'UPDATE'});
    },
    setConnected,
    logout,
  };

  return (
    <UserInfoContext.Provider value={value}>
      {children}
    </UserInfoContext.Provider>
  );
};

export {UserInfoContext, UserInfoProvider};
