/* eslint-disable */
import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
import withStyles from '@mui/styles/withStyles';
// @mui/icons-material

// core components
import cardStyle from '../../assets/jss/material-dashboard-react/components/cardStyle';

type Props = {
  classes: any;
  className?: string;
  plain?: boolean;
  profile?: boolean;
  chart?: boolean;
  children: any;
};

function Card({...props}: Props) {
  const {classes, className, children, plain, profile, chart, ...rest} = props;
  const cardClasses = classNames({
    [classes.card]: true,
    [classes.cardPlain]: plain,
    [classes.cardProfile]: profile,
    [classes.cardChart]: chart,
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [className]: className !== undefined,
  });
  return (
    <div className={cardClasses} {...rest}>
      {children}
    </div>
  );
}

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ card: { border: string; margin... Remove this comment to see the full error message
export default withStyles(cardStyle)(Card);
