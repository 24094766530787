import {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router';
import {toast} from 'react-toastify';

interface Props {
  to: string;
}

const Redirect = ({to}: Props) => {
  const navigate = useNavigate();
  const {search} = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(search);
    if (query.get('expiredSession') !== null) {
      toast.error('Session expirée, veuillez vous reconnecter');
    }
    navigate(to);
  }, [to, navigate, search]);

  return null;
};

export default Redirect;
