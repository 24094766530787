import React from 'react';
import { withStyles } from '@mui/styles';
import loginPageStyle from '../../assets/jss/material-dashboard-react/views/loginPage';
import mainLogo from '../../assets/img/logo_new_line_white.svg';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Card from '../../components/Card/Card';
import CardHeader from '../../components/Card/CardHeader';
import CardBody from '../../components/Card/CardBody';

const PrivacyPolicy: React.FC<any> = ({ classes }) => {
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <div className={classes.mainLogo}>
            <GridItem xs={7} sm={5} md={4} style={{ textAlign: 'center' }}>
              <img className={classes.logoImg} src={mainLogo} alt="Louons-Le" />
            </GridItem>
          </div>
        </GridItem>
        <GridItem xs={10} sm={10} md={8}>
          <Card>
            <CardHeader color="primary" className={classes.cardHeader}>
              <h1 className={classes.title}>POLITIQUE DE CONFIDENTIALITE</h1>
            </CardHeader>
            <CardBody>
              <h2>Préambule</h2>
              <p>
                La présente politique de confidentialité vise à informer tout
                utilisateur du site et de l’application Louons Le :
                <ul>
                  <li>
                    Sur la politique de Louons Le en matière de collecte de
                    données personnelles, étant précisé que sont considérées
                    comme des données personnelles, toute information permettant
                    d’identifier un utilisateur. A ce titre, il peut s’agir
                    entre autres : de ses noms et prénoms, de son âge, de son
                    adresse postale ou email, de sa localisation ou encore de
                    son adresse IP.
                  </li>
                  <li>Les droits dont il dispose concernant ces données.</li>
                  <li>
                    Sur le responsable du traitement des données à caractère
                    personnel collectées et/ou traités.
                  </li>
                  <li>Sur l’utilisation et la divulgation de ces données.</li>
                </ul>
              </p>
              <h2>
                Identité et coordonnées de l’organisme et du responsable du
                traitement de données
              </h2>
              <p>
                L’application et le site web Louons Le sont développés par
                Monsieur Nicolas DECAYEUX.
                <br />
                Il peut être contacté par courriel à l’adresse :
                <br />
                <a href="mailto:contact@louons-le.fr">contact@louons-le.fr</a>.
              </p>
              <h2>Données collectées et finalité de la collecte</h2>
              <p>
                Les données personnelles collectées par Louons Le sont les
                suivantes :
                <ul>
                  <li>Nom et prénom (s)</li>
                  <li>courriel</li>
                </ul>
                Ces données sont collectées au moment de l’inscription. Cette
                collecte répond aux finalités suivantes :
                <ul>
                  <li>
                    permettre une identification sécurisée de chaque
                    utilisateur,
                  </li>
                  <li>
                    permettre le fonctionnement de l'application Louons-Le
                  </li>
                  <li>
                    sécuriser les plateformes Louons Le, et prévenir toute
                    fraude.
                  </li>
                </ul>
              </p>
              <h2>
                Bases légales de la collecte / caractère obligatoire de la
                collecte des données
              </h2>
              <h3>Intérêt légitime</h3>
              <p>
                Le traitement des données personnelles collectées est nécessaire
                pour assurer un accès aux comptes de chaque utilisateur, et par
                conséquent pour l’accès aux services proposés par les
                plateformes de Louons Le.
              </p>
              <h3>Consentement</h3>
              <p>
                Par ailleurs Louons Le sollicite également le consentement de
                chaque utilisateur à la collecte de ses données personnelles au
                moment de son inscription, aux fins de la sécurisation de son
                compte.
              </p>
              <h2>Destinataires des données collectées</h2>
              <p>
                Le site web Louons Le et les données personnelles collectées
                sont hébergés par :
                <address>
                  OVH SAS
                  <br />2 rue Kellermann – BP 80157 59053 ROUBAIX CEDEX 1
                  <br />
                  Politique de protection des données :
                  <br />
                  <a href="https://www.ovhcloud.com/fr/personal-data-protection/">
                    https://www.ovhcloud.com/fr/personal-data-protection/
                  </a>
                </address>
                <br />
                L’intégralité des données personnelles collectées par Louons Le
                est réservé à son usage exclusif. Aucune des données collectées
                ne fait l’objet de transfert vers un tiers.
              </p>
              <h2>
                Délai de conservation des données et les conditions de
                suppression
              </h2>
              <p>
                Les données personnelles sont conservées pendant la durée
                d’utilisation des plateformes de Louons Le.
              </p>
              <h2>Droit d’accès, de modification, de suppression</h2>
              <p>
                Tout utilisateur des plateformes de Louons Le dispose d’un droit
                d’accès, de modification et de suppression des données
                personnelles le concernant. A ce titre il peut demander à
                consulter ses données personnelles, demander leur modification
                ou leur suppression.
              </p>
              <h2>Coordonnées du délégué à la protection des données</h2>
              <p>
                Pour exercer ses droits, l’utilisateur doit adresser un mail à
                Monsieur Nicolas DECAYEUX à l’adresse suivante :
                <br />
                <a href="mailto:contact@louons-le.fr">contact@louons-le.fr</a>.
                <br />
                Afin que le responsable du traitement des données puisse faire
                droit à sa demande, l’utilisateur devra lui communiquer une
                preuve de son identité en plus de ses nom, prénoms et
                identifiant de compte.
              </p>
              <h2>Les droits de réclamation auprès de la CNIL</h2>
              <p>
                Chaque utilisateur a le droit de déposer une plainte auprès de
                la Commission Nationale de l'Informatique et des Libertés
                (CNIL).
                <br />
                Pour plus d’informations sur vos droits, consultez le site
                internet <a href="https://cnil.fr">https://cnil.fr</a>.
              </p>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

// @ts-expect-error
export default withStyles(loginPageStyle)(PrivacyPolicy);
