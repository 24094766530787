import {AlertColor} from '@mui/material';
import React, {createContext, useCallback, useState} from 'react';
import {graphqlErrorToString} from '../utils/errors';

export interface Message {
  severity: AlertColor;
  content: string;
}

export interface MessageContainerController {
  addMessage: (msg: Message, clear: boolean) => void;
  clear: () => void;
}

export interface MessageContainersContextType {
  addContainer: (
    name: string,
    containerController: MessageContainerController,
  ) => void;
  removeContainer: (name: string) => void;
  sendErrorMessage: (containerName: string, message: string) => void;
  onErrorGql: (containerName: string) => (errors: any) => void;
  clear: (containerName: string) => void;
}

export const MessageContainersContext =
  createContext<MessageContainersContextType>({
    addContainer: () => {},
    removeContainer: () => {},
    sendErrorMessage: () => {},
    onErrorGql: () => () => {},
    clear: () => {},
  });

export const MessageContainersProvider = ({children}: {children: any}) => {
  const [containers, setContainers] = useState<
    Record<string, MessageContainerController>
  >({});

  const addContainer = useCallback((name: string, container: any) => {
    setContainers(prevContainers => ({
      ...prevContainers,
      [name]: container,
    }));
  }, []);
  const removeContainer = (name: string) => {
    setContainers(oldContainers => {
      const {[name]: removedContainer, ...newContainers} = oldContainers;
      return newContainers;
    });
  };
  const sendErrorMessage = (containerName: string, message: string) => {
    const container = containers[containerName];
    if (container) {
      container.addMessage({content: message, severity: 'error'}, true);
    }
  };
  const onErrorGql = (containerName: string) => (error: any) => {
    const errorString = graphqlErrorToString(error);
    if (errorString) {
      sendErrorMessage(containerName, errorString);
    }
  };
  const clear = (containerName: string) => {
    const container = containers[containerName];
    if (container) {
      container.clear();
    }
  };

  const value = {
    // eslint-disable-line
    addContainer,
    removeContainer,
    sendErrorMessage,
    onErrorGql,
    clear,
  };

  return (
    <MessageContainersContext.Provider value={value}>
      {children}
    </MessageContainersContext.Provider>
  );
};
