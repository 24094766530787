import React, { lazy, Suspense } from 'react';
import PrivacyPolicy from '../views/PrivacyPolicy';

const Login = lazy(() => import('../views/Login/Login'));
const Register = lazy(() => import('../views/Register/Register'));
const NoTeam = lazy(() => import('../views/NoTeam/NoTeam'));
const IndexRedirect = lazy(() => import('../views/IndexRedirect'));
const Dashboard = lazy(() => import('../views/Dashboard'));
const RequestResetPassword = lazy(
  () => import('../views/ResetPassword/RequestResetPassword'),
);
const ResetPassword = lazy(
  () => import('../views/ResetPassword/ResetPassword'),
);

const indexRoutes = [
  {
    path: '/login',
    component: (
      <Suspense>
        <Login />
      </Suspense>
    ),
  },
  {
    path: '/register',
    component: (
      <Suspense>
        <Register />
      </Suspense>
    ),
  },
  {
    path: '/reset-password/:token',
    component: (
      <Suspense>
        <ResetPassword />
      </Suspense>
    ),
  },
  {
    path: '/reset-password',
    component: (
      <Suspense>
        <RequestResetPassword />
      </Suspense>
    ),
  },
  {
    path: '/privacy-policy',
    component: (
      <Suspense>
        <PrivacyPolicy />
      </Suspense>
    ),
    hide: true,
  },
  {
    path: '/no-team',
    component: (
      <Suspense>
        <NoTeam />
      </Suspense>
    ),
    private: true,
    hide: true,
  },
  {
    path: '/',
    component: (
      <Suspense>
        <IndexRedirect />
      </Suspense>
    ),
    private: true,
    hide: true,
  },
  {
    path: '/*',
    component: (
      <Suspense>
        <Dashboard />
      </Suspense>
    ),
    private: true,
    hide: true,
  },
];

export default indexRoutes;
